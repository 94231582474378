<template>
    <main class="about">
        <div class="about-main__body">
            <div class="about-main__content">
                <h1 class="about-main__title">Acerca de MEGAdescuentos</h1>

                <p class="about-main__text">
                    Hurtado Deals creó una comunidad de ahorradores que desean compartir ofertas, cupones,
                    promociones y experiencias de compra para sacarle el máximo provecho a su dinero; se trata
                    de MEGAdescuentos. Esta plataforma tiene concepto innovador en su ramo, pues además de que
                    reúne las marcas más populares de México, ofrece a cada uno de sus miembros recompensas
                    por sus participaciones.
                </p>

                <img
                    class="about-main__img"
                    :src="$assets.illustration.aboutUs.megadescuentos.bannerAboutUs1"
                    alt="Megadescuentos banner"
                />

                <h2 class="about-main__subtitle">Somos un sitio de comunidad</h2>

                <p class="about-main__text">
                    Más allá de ser un buscador de
                    <NuxtLink class="about-main__link" to="/cupones">cupones</NuxtLink> y ofertas,
                    MEGAdescuentos es una comunidad que permite a los usuarios interactuar, votar por las
                    ofertas publicadas, reportar publicaciones, guardar los cupones y promociones que les
                    interesan, y publicar sus hallazgos en algunas de las tiendas para que otros puedan
                    aprovechar las rebajas.
                </p>

                <p class="about-main__text">
                    Asimismo, en el sitio nos hemos dedicado a crear espacios para que los usuarios puedan
                    compartir sus códigos de referidos de distintas marcas, como Temu, para que consigan
                    beneficios adicionales por cada persona que lo use para registrarse.
                </p>

                <p class="about-main__text">
                    MEGAdescuentos también contamos con una sección de
                    <NuxtLink class="about-main__link" to="/blog">Blog</NuxtLink> para ofrecer información
                    útil sobre el funcionamiento de algunas de las plataformas más populares, como SHEIN,
                    ofrecemos consejos para comprar en eventos comerciales, todo sobre descuentos que están
                    disponibles para estudiantes y militares, recopilaciones de tiendas que ofrecen regalos
                    gratis en tu cumpleaños, y todo lo que necesitas saber sobre productos, marcas, y más.
                </p>

                <p class="about-main__text">
                    Además, a diferencia de otras comunidades de ahorradores, ofrecemos recompensas a los
                    miembros por sus interacciones. Así es, todas las personas que se registran tienen la
                    oportunidad de ganar monedas para canjear por premios por acciones como inicio de sesión,
                    publicar o comentar cupones y ofertas, compartir dudas en foros y mucho más. Al acumular
                    un número determinado de monedas, pueden acceder a tarjetas de regalo, souvenirs, entre
                    otros atractivos obsequios.
                </p>

                <img
                    class="about-main__img"
                    :src="$assets.illustration.aboutUs.megadescuentos.bannerAboutUs2"
                    alt="Megadescuentos banner"
                />

                <h2 class="about-main__subtitle">Extensa red de afiliados</h2>

                <p class="about-main__text">
                    Uno de los puntos fuertes de MEGAdescuentos es que pone a disposición de los usuarios
                    cupones que no están disponibles en ningún otro lugar. Esto es posible gracias a nuestra
                    extensa red de afiliados de Hurtado Deals, que colabora de cerca con Awin, Soicos,
                    Tradetracker, y otros líderes del sector.
                </p>

                <p class="about-main__text">
                    Nuestras decenas de cupones exclusivos son uno de los motivos principales para conseguir
                    ahorros extraordinarios en tiendas, almacenes y marcas de distintos rubros. Además, antes
                    de ser publicado, cada código es probado por nuestro equipo de colaboradores para
                    garantizar que funcione correctamente. De esta manera, podemos ofrecer información precisa
                    sobre el monto mínimo de compra, máximo de descuento, productos en los que aplica y si es
                    para nuevos usuarios o compradores recurrentes.
                </p>

                <p class="about-main__text">
                    Adicionalmente, ofrecemos a nuestros usuarios información detallada y anticipada de las
                    campañas más importantes de distintas marcas. En particular se destacan las Ventas
                    Nocturnas, Promociones de Aniversario, Ofertas Flash, Rebajas de Temporada y más.
                </p>

                <h2 class="about-main__subtitle">Así es como ganamos dinero</h2>

                <p class="about-main__text">
                    Unirte a MEGAdescuentos es gratis para ti, pero mantenerlo actualizado y funcionando
                    diariamente cuesta dinero. ¿Cómo le hacemos? Nuestro sitio web consigue recursos a través
                    de la
                    <a class="about-main__link" href="https://www.hurtadodeals.com/" target="_blank"
                        >red de afiliados de Hurtado Deals</a
                    >, integrada por marcas y tiendas que nos pagan una comisión por cada compra que los
                    usuarios hacen usando nuestros enlaces o cupones exclusivos.
                </p>

                <p class="about-main__text">
                    Desde luego, puedes tener la tranquilidad de que nuestras ganancias no representan ningún
                    costo para el comprador; tú siempre podrás aprovechar las ofertas sin costo adicional de
                    ningún tipo. Nuestro beneficio se complementa con nuestro objetivo primordial, que es
                    poner a tu alcance las mejores oportunidades de ahorro.
                </p>

                <img
                    class="about-main__img"
                    :src="$assets.illustration.aboutUs.megadescuentos.bannerAboutUs3"
                    alt="Megadescuentos banner"
                />

                <h2 class="about-main__subtitle">Cobertura en eventos comerciales</h2>

                <p class="about-main__text">
                    Cada año, en MEGAdescuentos nos encargamos de dar seguimiento a los eventos comerciales
                    más grandes del país, como el
                    <NuxtLink class="about-main__link" to="/eventos/hot-sale-20">Hot Sale</NuxtLink>, Buen
                    Fin, Black Friday, Singles Day, Cyber Monday, Ventas Nocturnas, y mucho más, para que
                    nuestros miembros accedan a información precisa sobre los mejores descuentos, cupones,
                    ofertas y promociones.
                </p>
                <p class="about-main__text">
                    Para ayudar a los usuarios a comparar precios y elegir solo las mejores ofertas, nuestro
                    equipo realiza recopilaciones de productos con los descuentos más prometedores, hace
                    simulaciones de compra con cupones para garantizar que sigan activos y comparte cuáles son
                    las bonificaciones bancarias que aplican para acumular beneficios; en suma, se encarga de
                    ofrecer confianza a los usuarios para hacer sus compras al precio más bajo posible.
                </p>

                <h2 class="about-main__subtitle">Publicamos cupones verificados</h2>

                <p class="about-main__text">
                    Para ayudarte a encontrar cupones que realmente funcionen, nos encargamos de probar los
                    códigos antes de publicarlos, incluso aquellos que nos han compartido nuestros afiliados.
                    Nuestra intención es ofrecerte información clara sobre el descuento que recibirás, el
                    monto mínimo de compra que debes hacer, y si es posible combinar ofertas y códigos para
                    sumar beneficios.
                </p>

                <p class="about-main__text">
                    Por este motivo, puedes tener la certeza de que cada cupón que encuentres en nuestro sitio
                    te ayudará a conseguir ese ahorro que tanto necesitas. Descubre más información sobre cómo
                    <NuxtLink
                        class="about-main__link"
                        to="/blog/guias-de-ahorro/unete-gana-y-ahorra-te-decimos-por-que-confiar-en-megadescuentos"
                        >revisamos y probamos los cupones</NuxtLink
                    >
                    en MEGAdescuentos.
                </p>

                <img
                    class="about-main__img"
                    :src="$assets.illustration.aboutUs.megadescuentos.bannerAboutUs4"
                    alt="Megadescuentos banner"
                />

                <h2 class="about-main__subtitle">Tiendas activas en MEGAdescuentos</h2>

                <p class="about-main__text">
                    MEGAdescuentos
                    <NuxtLink class="about-main__link" to="/marcas">reúne más de 200 tiendas</NuxtLink>,
                    comercios y plataformas de comercio electrónico para que los usuarios encuentren cupones,
                    ofertas y promociones en productos y servicios de todas las categorías, desde moda,
                    alimentos, delivery, transporte, hasta viajes y servicios web.
                </p>

                <p class="about-main__text">
                    De esta manera, nuestros miembros encontrarán desde productos de todas las categorías con
                    rebajas en Amazon, Temu, AliExpress; hasta lo mejor del supermercado en Walmart, Chedraui,
                    Jüsto; descuentos en viajes con Vivaerobus, Volaris, Aeroméxico, ropa para toda la familia
                    en SHEIN, Levi's; hosting en Banahosting, Sered Hosting, y más. Además, constantemente se
                    unen más marcas a nuestro proyecto para ofrecer los mejores descuentos en un solo lugar.
                </p>

                <h2 class="about-main__subtitle">Nuestro Equipo</h2>

                <section class="about-main__team">
                    <article class="team-card">
                        <div class="team-card__info">
                            <img
                                class="info-img"
                                :src="$assets.illustration.aboutUs.megadescuentos.aboutPeople1"
                                alt="Megadescuentos banner"
                            />

                            <div class="info-content">
                                <h3 class="info-content__name">Juanca Hurtado</h3>
                                <h3 class="info-content__job">CEO de Hurtado Global Media Group</h3>
                                <nav class="info-content__nav">
                                    <a
                                        class="info-content__link"
                                        href="https://www.linkedin.com/in/juanca-hurtado-55569746/"
                                        rel="nofollow"
                                        target="_blank"
                                        >LinkedIn</a
                                    >
                                    <a
                                        class="info-content__link"
                                        href="https://www.instagram.com/jotahmega/"
                                        rel="nofollow"
                                        target="_blank"
                                        >Instagram</a
                                    >
                                </nav>
                            </div>
                        </div>

                        <p class="team-card__text">
                            Con una amplia experiencia en productos y servicios en tema de ahorro y cupones en
                            línea, Juanca es el líder del equipo de MEGAdescuentos, y el principal encargado
                            de construir y mantener la red de colaboración de afiliados, que permite a nuestro
                            sitio tener códigos exclusivos. Él tuvo la visión de crear una comunidad que,
                            además de ofrecer oportunidades y consejos para economizar, recompensará a sus
                            miembros por ayudar a otros a conseguir los mejores precios del mercado.
                        </p>
                    </article>

                    <hr class="about-main__team-line" />

                    <article class="team-card">
                        <div class="team-card__info">
                            <img
                                class="info-img"
                                :src="$assets.illustration.aboutUs.megadescuentos.aboutPeople2"
                                alt="Megadescuentos banner"
                            />

                            <div class="info-content">
                                <h3 class="info-content__name">Andrea Méndez</h3>
                                <h3 class="info-content__job">Content Manager</h3>
                                <nav class="info-content__nav">
                                    <a
                                        class="info-content__link"
                                        href="https://www.linkedin.com/in/andrea-mendez-43b80018b/"
                                        rel="nofollow"
                                        target="_blank"
                                        >LinkedIn</a
                                    >
                                </nav>
                            </div>
                        </div>

                        <p class="team-card__text">
                            Desde el nacimiento de MEGAdescuentos, Andrea se encarga de planificar y organizar
                            el calendario editorial de nuestro sitio web. Parte de su trabajo también es
                            coordinar las campañas de las marcas que nos ofrecen cupones exclusivos. Asimismo,
                            brinda capacitación constante al equipo sobre la verificación de cupones y ofertas
                            para garantizar la mejor experiencia posible a los miembros de nuestra comunidad.
                        </p>
                    </article>

                    <hr class="about-main__team-line" />

                    <article class="team-card">
                        <div class="team-card__info">
                            <img
                                class="info-img"
                                :src="$assets.illustration.aboutUs.megadescuentos.aboutPeople3"
                                alt="Megadescuentos banner"
                            />

                            <div class="info-content">
                                <h3 class="info-content__name">Concepción Pacheco</h3>
                                <h3 class="info-content__job">Content Assistant</h3>
                                <nav class="info-content__nav">
                                    <a
                                        class="info-content__link"
                                        href="https://www.linkedin.com/in/conepacheco/"
                                        rel="nofollow"
                                        target="_blank"
                                        >LinkedIn</a
                                    >
                                </nav>
                            </div>
                        </div>

                        <p class="team-card__text">
                            Se unió al equipo de MEGAdescuentos para monitorear las tiendas, rastrear las
                            ofertas más destacadas, verificar cupones y moderar las colaboraciones de otros
                            usuarios. Adicionalmente, Cone hace un excelente trabajo al mantener el contenido
                            de las tiendas actualizado, gracias a su investigación, nuestra comunidad puede
                            aprovechar al máximo programas de recompensas, recopilación de puntos, membresías,
                            tarjetas y mucho más.
                        </p>
                    </article>
                </section>
            </div>
        </div>
    </main>
</template>

<script lang="ts" setup>
const Route = useRoute()

const {
    public: { origin },
} = useRuntimeConfig()

const description =
    'Aprende más sobre MEGAdescuentos y quién está detrás. Conoce quiénes somos, nuestras actividades y por qué elegirnos.'
const title = 'Quiénes somos | MEGAdescuentos'

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})
</script>

<style lang="postcss" scoped>
.about {
    &-main {
        @apply m-0 mx-auto space-x-2 lg:container md:mb-10 md:mt-4 lg:max-w-screen-xl;
        &__body {
            @apply mx-auto my-4 max-w-screen-xl overflow-hidden bg-white lg:container md:rounded-3xl lg:w-3/4;
        }
        &__content {
            @apply px-6 py-4;
        }

        &__title {
            @apply mb-6 mt-4 text-center text-2xl font-semibold;
        }

        &__subtitle {
            @apply mb-4 text-2xl font-semibold;
        }

        &__img {
            @apply mb-6 block w-full object-contain md:mb-8 md:h-[500px];
        }

        &__text {
            @apply mb-6 text-base;
        }

        &__link {
            @apply font-semibold underline;
        }

        &__team {
            @apply mt-8 space-y-8;

            &-line {
                @apply w-full bg-gray-200;
            }

            .team-card {
                @apply flex flex-col gap-2 last:mb-4;

                &__info {
                    @apply flex gap-4;

                    .info-img {
                        @apply h-32 w-32 rounded-3xl object-contain;
                    }

                    .info-content {
                        @apply flex flex-col justify-center;

                        &__name {
                            @apply text-lg font-semibold;
                        }

                        &__job {
                            @apply text-base;
                        }

                        &__nav {
                            @apply flex items-center space-x-3;

                            a {
                                @apply underline;
                            }
                        }
                    }
                }

                &__text {
                    @apply col-span-5 row-start-4 text-base;
                }
            }
        }
    }
}
</style>
