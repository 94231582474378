<template>
    <div
        v-if="(currentPage && currentPage !== initialPage) || meta.last_page === currentPage"
        class="infinite-scroll-footer"
    >
        <div class="infinite-scroll-footer__container">
            <button @click="scrollTop" type="button" class="infinite-scroll-footer__button">
                <img :src="$assets.primary.arrowDown" alt="" />
                <span class="hidden md:block">Ir arriba</span>
            </button>

            <div class="infinite-scroll-footer__pagination">
                <slot name="pagination"></slot>
            </div>

            <button @click="show = !show" type="button" class="infinite-scroll-footer__button">
                <img :class="{ 'rotate-180 transform': show }" :src="$assets.primary.arrowDown" alt="" />
                <span class="hidden md:block">{{ show ? 'Ocultar' : 'Mostrar' }} pie de página</span>
            </button>
        </div>

        <div class="infinite-scroll-footer__footer-wrapper">
            <AppFooter v-if="show"></AppFooter>
        </div>
    </div>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'
import type { Helpers } from '~/types/helpers'

const show = ref(false)

const props = defineProps({
    meta: {
        type: Object as PropType<Helpers.Meta>,
        required: true,
    },
    initialPage: Number,
})

const Route = useRoute()

const currentPage = computed(() => Number(Route.query?.page))

const scrollTop = () => {
    document.documentElement.style.scrollBehavior = 'smooth'

    window.scroll(0, 1)

    document.documentElement.style.scrollBehavior = 'unset'
}
</script>

<style lang="postcss">
.infinite-scroll-footer {
    @apply fixed bottom-0 left-0 right-0 w-full border-t border-gray-300 bg-white;
    &__container {
        @apply container flex items-center justify-between;
    }
    &__button {
        @apply flex h-8 w-8 items-center space-x-2 rounded-full border border-gray-200 px-2 py-2 lg:w-auto lg:border-none lg:px-5 lg:hover:bg-black lg:hover:bg-opacity-10;
        &:first-of-type {
            img {
                @apply rotate-180 transform;
            }
        }
    }

    &__footer-wrapper {
        @apply max-h-[70vh] overflow-y-auto lg:max-h-[auto];
    }
}
</style>
