<template>
    <div class="email-login-strategy" :class="page ? 'bg-white shadow-lg' : 'bg-site-background'">
        <slot></slot>

        <h2 class="email-login-strategy__title">
            {{ $lang.components.formEmailLoginStrategy.email_sended }}
        </h2>
        <p class="email-login-strategy__subtitle">
            {{ $lang.components.formEmailLoginStrategy.check_email }}
        </p>
        <form autocomplete="off" class="email-login-strategy__form" @submit.prevent>
            <fieldset class="email-login-strategy__fieldset">
                <InputGeneric
                    v-for="(text, index) in values"
                    :key="index"
                    ref="digits"
                    :name="'digit_pos_' + index"
                    type="text"
                    autocomplete="negative"
                    maxlength="1"
                    inputmode="numeric"
                    class="email-login-strategy__input"
                    :invalid="!!form.validationStatus.code"
                    @update:model-value="(value: any) => onInput(index, value)"
                />
            </fieldset>
            <div v-if="form.validationStatus.code" class="email-login-strategy__error-message">
                <img :src="$assets.red.formError" alt="icon" />
                <p>{{ form.validationStatus.code }}</p>
            </div>
        </form>
        <ButtonGeneric
            ref="submit"
            class="email-login-strategy__submit-button"
            :class="{ ready }"
            :info="{
                text: 'Siguiente',
                icon: $assets.primary.logout,
                handler: signIn,
            }"
        ></ButtonGeneric>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useAuthStore } from '~/store/auth'
import { useRootStore } from '~/store/root'

export default defineComponent({
    name: 'FormEmailLoginStrategy',
    props: {
        page: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        const setCookies = useSetCookies()
        const AuthStore = useAuthStore()
        const { setSiteNotification } = useRootStore()
        const RootStore = useRootStore()
        return {
            AuthStore,
            RootStore,
            ready: false,
            setSiteNotification,
            setCookies,
            values: ['', '', '', '', '', ''],
            form: {
                data: {
                    email: AuthStore.recoveryEmail || '',
                    code: '',
                },
                validationStatus: {
                    code: false as boolean | string,
                },
            },
        }
    },
    watch: {
        values: {
            deep: true,
            handler() {
                this.form.data.code = this.values.join('')
                this.ready = this.form.data.code.length === 6
            },
        },
    },
    methods: {
        onInput(index: number, value: string) {
            if (value && value.trim() && !isNaN(Number(value))) {
                if (index < 5) (this.$refs.digits as any)[index + 1].focus()
                this.values[index] = value
            } else {
                ;(this.$refs.digits as any)[index].setValue('')

                if (index > 0) (this.$refs.digits as any)[index].select()

                this.values[index] = ''
            }
            this.form.data.code = this.values.join('')
        },
        async signIn() {
            this.RootStore.setLoadingStatus(true)

            const result = await this.AuthStore.signInWithEmail(this.form.data)

            if (!result) {
                this.RootStore.setLoadingStatus(false)
                return
            }

            if (result.feedback === 'params_validation_failed') {
                this.form.validationStatus.code = result.data.code?.[0] || ''
            }
            if (result.feedback === 'not_found') {
                this.form.validationStatus.code = result.data.message
            }

            if (result.feedback === 'token_created_successfull') {
                this.setCookies(result.data.expires_at * 1000, result.data.access_token)
                this.AuthStore.setAuthModal(null)

                if (this.$route.name === ' homepage') {
                    location.reload()
                } else {
                    location.href = '/'
                }
            } else {
                this.RootStore.setLoadingStatus(false)
            }
        },
    },
})
</script>

<style lang="postcss" scoped>
.email-login-strategy {
    @apply relative max-w-lg space-y-3 rounded-xl bg-white p-5;
    @apply md:max-w-lg lg:max-w-lg !important;

    &__title {
        @apply text-center text-lg font-medium text-gray-800;
    }
    &__subtitle {
        @apply text-center text-sm text-gray-800;
    }
    &__fieldset {
        @apply mb-3 flex flex-nowrap justify-center space-x-3;
    }
    &__input {
        input {
            @apply w-8 p-2 font-mono;
            @apply text-center !important;
        }
    }
    &__error-message {
        @apply mb-3 flex w-full items-center justify-center space-x-1.5 text-sm text-red-500;
        img {
            @apply w-4;
        }
    }
    &__submit-button {
        @apply w-full border-2 border-site-primary bg-white text-site-primary transition-colors;
        img {
            @apply transition-all;
        }

        &.ready {
            @apply bg-site-primary-hover text-white;
            img {
                @apply brightness-0 invert;
            }
        }
    }
    @screen lg {
        &__submit-button {
            &:hover {
                @apply bg-site-primary-hover text-white;
                img {
                    @apply brightness-0 invert;
                }
            }
        }
    }
}
</style>
