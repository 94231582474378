<template>
    <div class="infinite-scroll-pagination">
        <button type="button" class="first-page" @click="() => paginate(1)">1</button>

        <span class="mx-2 block">...</span>

        <button
            :disabled="currentPage === 1"
            @click="() => paginate(currentPage - 1)"
            type="button"
            class="prev-page"
        >
            <img :src="$assets.primary.arrowLeft" alt="" />
        </button>

        <button type="button" class="current-page">
            <span class="hidden md:inline">Página</span> {{ currentPage }}
        </button>

        <button
            :disabled="currentPage === meta.last_page"
            @click="() => paginate(currentPage + 1)"
            type="button"
            class="next-page"
        >
            <img :src="$assets.primary.arrowRight" alt="" />
        </button>

        <span class="mx-2 block">...</span>

        <button @click="() => paginate(meta.last_page)" type="button" class="last-page">
            {{ meta.last_page }}
        </button>
    </div>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'
import type { Helpers } from '~/types/helpers'

defineProps({
    meta: {
        type: Object as PropType<Helpers.Meta>,
        required: true,
    },
    initialPage: Number,
})

const Route = useRoute()

const emits = defineEmits(['onPaginate'])

const paginate = (page: number) => emits('onPaginate', page)

const currentPage = computed(() => Number(Route.query?.page))
</script>

<style lang="postcss">
.infinite-scroll-pagination {
    @apply flex items-center py-2 font-medium;
    .next-page {
        @apply mr-auto;
    }
    .prev-page {
        @apply ml-auto;
    }
    .next-page,
    .prev-page {
        @apply block h-10 w-10 rounded-full border border-gray-200 p-2.5 disabled:opacity-35 lg:border-none lg:hover:bg-black lg:hover:bg-opacity-10;
        img {
            @apply h-full w-full object-contain;
        }
    }
    .last-page,
    .first-page {
        @apply h-10 w-10 rounded-full border border-gray-200 p-2 leading-6 text-site-primary lg:border-none lg:hover:bg-black lg:hover:bg-opacity-10;
    }
    .current-page {
        @apply h-8 rounded-full px-4 py-2 leading-4 lg:hover:bg-black lg:hover:bg-opacity-10 lg:hover:text-site-primary;
    }
}
</style>
