<template>
    <div class="modal-auth-login">
        <div class="modal-auth-login__content">
            <div
                class="modal-auth-login__left"
                :class="{ 'bg-site-background': !pageVersion, 'bg-white': pageVersion }"
            >
                <h2 class="modal-auth-login__title">
                    {{ $lang.components.modalAuthLogin.login_or_register }}
                </h2>
                <h3 class="modal-auth-login__subtitle">
                    {{ $lang.components.modalAuthLogin.join_adventages }}
                </h3>
                <ul class="modal-auth-login__list">
                    <li v-for="(item, index) in pros" :key="index">
                        <p>{{ item.text }}</p>
                    </li>
                </ul>
                <ButtonGeneric
                    class="modal-auth-login__change-modal-button"
                    :info="{
                        text: $lang.components.modalAuthLogin.create_account,
                        handler: changeModal,
                    }"
                />
            </div>
            <div class="modal-auth-login__right">
                <h2 class="modal-auth-login__normal-title">
                    {{ $lang.components.modalAuthLogin.login_with_social }}
                </h2>

                <ButtonGeneric
                    v-for="info in rrss"
                    :key="info.iconAlt"
                    :info="info"
                    class="modal-auth-login__social-button"
                ></ButtonGeneric>

                <h2 class="modal-auth-login__normal-title">
                    {{ $lang.components.modalAuthLogin.login_with_email }}
                </h2>
                <form class="modal-auth-login__form-login" @submit.prevent="() => signIn(pageVersion)">
                    <InputGeneric
                        v-model="form.data.username"
                        class="modal-auth-login__field"
                        type="text"
                        name="name"
                        :placeholder="$lang.components.modalAuthLogin.email_or_username"
                        :invalid="form.validationStatus.username"
                    >
                        {{ form.validationStatus.username }}
                    </InputGeneric>
                    <AuthInputPassword
                        v-model="form.data.password"
                        class="modal-auth-login__field"
                        name="password"
                        :placeholder="$lang.components.modalAuthLogin.password"
                        :invalid="form.validationStatus.password"
                    >
                        {{ form.validationStatus.password }}
                    </AuthInputPassword>

                    <button type="button" class="modal-auth-login__reset-button" @click="forgotPassword">
                        {{ $lang.components.modalAuthLogin.forgot_password }}
                    </button>

                    <InputCheckbox id="rememberMe" v-model="rememberMe" class="modal-auth-login__field">
                        {{ $lang.components.modalAuthLogin.remember_login }}
                    </InputCheckbox>

                    <ButtonGeneric
                        type="submit"
                        class="modal-auth-login__submit-button"
                        :info="{
                            text: $lang.components.modalAuthLogin.login,
                            icon: $assets.primary.logout,
                            handler: () => null,
                        }"
                    ></ButtonGeneric>
                    <!--  <NuxtTurnstile v-model="turnstileVerified" /> -->
                </form>
                <InputAlert v-if="loginError" :message="loginError" />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useAuthStore } from '~/store/auth'
import type { Api } from '~~/global'
import { useSocialAuth } from '~/composables/SocialAuth'
import { useRootStore } from '~/store/root'

type SocialAuthResponse =
    | Api.Responses.Auth.SuccessSignIn
    | {
          error: true
          data: {
              access_token?: string[]
          }
          feedback: 'params_validation_failed'
      }

const props = defineProps({
    pageVersion: {
        type: Boolean,
        default: false,
    },
})

/* const turnstileVerified = ref() */

const AuthStore = useAuthStore()
const setCookies = useSetCookies()

const { $assets, $lang } = useNuxtApp()

const RootStore = useRootStore()

const loginError = ref(null) as Ref<null | string>

const { googleAuthInit, facebookAuthInit } = useSocialAuth()

const pros = [
    {
        icon: $assets.auth.login.icons.chat,
        text: $lang.components.modalAuthLogin.comment_vote_offers,
    },
    {
        icon: $assets.auth.login.icons.gift,
        text: $lang.components.modalAuthLogin.participate,
    },
    {
        icon: $assets.auth.login.icons.people,
        text: $lang.components.modalAuthLogin.joinus,
    },
]

const rememberMe = ref(false)

const form = ref({
    data: {
        username: '',
        password: '',
    },
    validationStatus: {
        username: false as string | boolean,
        password: false as string | boolean,
    },
})

const { buildHeaders, endpoints } = useApiConfig()

const rrss = [
    {
        text: $lang.components.modalAuthLogin.login_google,
        icon: $assets.social.googleAccount,
        iconAlt: 'Google button',
        handler: () => {
            /*     if (
                !turnstileVerified.value ||
                typeof turnstileVerified.value !== 'string' ||
                turnstileVerified.value.length === 0
            ) {
                RootStore.setSiteNotification({
                    text: 'Compruebe que no es un robot',
                    type: 'warning',
                    duration: 8000,
                })
                return
            } */
            RootStore.setLoadingStatus(true)
            googleAuthInit(async (err) => {
                if (err) {
                    RootStore.setLoadingStatus(false)
                    loginError.value = err.message
                }
            })
        },
    },
    {
        text: $lang.components.modalAuthLogin.login_facebook,
        icon: $assets.social.facebookAccount,
        iconAlt: 'Facebook button',
        handler: () => {
            /*  if (
                !turnstileVerified.value ||
                typeof turnstileVerified.value !== 'string' ||
                turnstileVerified.value.length === 0
            ) {
                RootStore.setSiteNotification({
                    text: 'Compruebe que no es un robot',
                    type: 'warning',
                    duration: 8000,
                })
                return
            } */
            AuthStore.setAuthModal({
                type: 'accept-terms',
                info: {
                    message: null,
                    rrssRegister: {
                        rrss: 'facebook',
                        handler: () => {
                            return facebookAuthInit(async (err, response) => {
                                if (err) {
                                    loginError.value = err.message
                                } else {
                                    if (response) {
                                        try {
                                            RootStore.setLoadingStatus(true)
                                            const result = await $fetch<SocialAuthResponse>(
                                                endpoints.authentication.socialStrategy.facebook,
                                                {
                                                    method: 'GET',

                                                    headers: buildHeaders(AuthStore.SessionToken),
                                                    params: {
                                                        access_token: response.authResponse.accessToken,
                                                    },
                                                },
                                            )

                                            if (result.feedback === 'token_created_successfull') {
                                                AuthStore.setAuthModal(null)

                                                setCookies(
                                                    result.data.expires_at * 1000,
                                                    result.data.access_token,
                                                )

                                                location?.reload()
                                            } else {
                                                RootStore.setLoadingStatus(false)
                                            }
                                        } catch (e) {
                                            console.log(e)
                                        }
                                        RootStore.setLoadingStatus(false)
                                    }
                                }
                            })
                        },
                    },
                },
            })
        },
    },
]

const route = useRoute()

const signIn = async (redirect = false) => {
    /* if (
        !turnstileVerified.value ||
        typeof turnstileVerified.value !== 'string' ||
        turnstileVerified.value.length === 0
    ) {
        RootStore.setSiteNotification({
            text: 'Compruebe que no es un robot',
            type: 'warning',
            duration: 8000,
        })
        return
    } */

    RootStore.setLoadingStatus(true)

    const result = await AuthStore.signIn(form.value.data)

    if (result?.feedback === 'params_validation_failed') {
        form.value.validationStatus = {
            username: result.data.username?.[0] || false,
            password: result.data.password?.[0] || false,
        }
        RootStore.setLoadingStatus(false)
        return
    }

    if (result.error) {
        RootStore.setSiteNotification({
            text: 'Error al iniciar sesión',
            dismissButtonText: 'Cerrar',
            type: 'error',
        })
        RootStore.setLoadingStatus(false)
    } else {
        if (result.feedback === 'token_created_successfull') {
            setCookies(result.data.expires_at * 1000, result.data.access_token)
        }
        AuthStore.setAuthModal(null)

        if (redirect) {
            const pathsToMAtch = [
                `/${$lang.routes.share}/${$lang.routes.chat}`,
                `/${$lang.routes.share}/${$lang.routes.coupon}`,
                `/${$lang.routes.share}/${$lang.routes.offer}`,
            ]

            location.pathname =
                route.redirectedFrom?.path && pathsToMAtch.includes(route.redirectedFrom?.path)
                    ? route.redirectedFrom?.path
                    : '/'
        } else location?.reload()
    }
}

const Router = useRouter()

const forgotPassword = () => {
    if (props.pageVersion) {
        Router.push('/' + $lang.routes.forgotPassword)
    } else {
        AuthStore.setAuthModal({ type: 'forgot-password' })
    }
}

const changeModal = () => {
    if (props.pageVersion) {
        Router.push('/' + $lang.routes.register)
    } else {
        AuthStore.setAuthModal({ type: 'sign-up' })
    }
}
</script>

<style lang="postcss" scoped>
.modal-auth-login {
    @apply overflow-hidden rounded-xl;
    &__content {
        @apply relative md:flex;
    }
    &__left {
        @apply flex flex-col justify-center space-y-3 p-5 md:w-1/2 md:flex-none;
    }

    &__title {
        @apply text-center text-xl leading-5;
    }
    &__subtitle {
        @apply text-center text-lg font-semibold text-site-primary;
    }
    &__list {
        @apply list-disc space-y-2 pl-8;
        li {
            @apply list-item items-center space-x-2 text-sm;
            &::marker {
                @apply text-site-primary;
            }
            img {
                @apply h-8 w-8;
            }
        }
    }
    &__change-modal-button {
        @apply w-full border-2 border-site-secondary bg-white text-site-secondary;
    }
    @screen lg {
        &__change-modal-button {
            @apply transition-colors;
            &:hover {
                @apply border-site-secondary bg-site-secondary text-white;
            }
        }
    }
    &__right {
        @apply space-y-3 border-t border-t-gray-200 bg-white p-5 md:flex md:w-1/2 md:flex-none md:flex-col md:justify-center md:border-t-0;
    }
    &__normal-title {
        @apply text-lg font-semibold;
    }
    &__form-login {
        @apply space-y-3;
    }
    &__submit-button {
        @apply border-2 border-site-primary bg-white text-site-primary;
    }
    @screen lg {
        &__submit-button {
            @apply transition-colors;
            img {
                @apply transition-all;
            }
            &:hover {
                @apply bg-site-primary-hover text-white transition-colors;
                img {
                    @apply brightness-0 invert;
                }
            }
        }
    }
    &__social-buttons {
        @apply flex h-10 flex-col items-start;
    }
    &__submit-button,
    &__social-button {
        @apply w-full;
    }
    &__social-button {
        @apply bg-white lg:transition-colors lg:hover:bg-site-primary lg:hover:text-white;
    }
    &__reset-button {
        @apply block max-w-max text-site-primary underline lg:no-underline lg:hover:underline;
    }
}
</style>
